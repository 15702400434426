<template>
  <v-container fluid>

    <div class="text-center ma-2">

      <v-snackbar
          multi-line
          min-height="100%"
          position="absolute"
          :color="snackbar_color"
          v-model="snackbar"
          timeout="3000"
      >
        {{ text_snackbar }}

        <template v-slot:actions>
          <v-btn
              color="pink"
              variant="text"
              @click="snackbar = false"
          >
            ×
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <v-row>
      <v-spacer></v-spacer>
      <!--      左边 启动按钮部分-->
      <v-col cols="3">
        <v-row>
          <v-col class="px-1">
            <v-text-field
                type="text"
                bg-color="#E1F5FE"
                label="国家码"
                variant="outlined"
                v-model="code_country"
                :disabled="isRun"
            ></v-text-field>

          </v-col>
          <v-col class="px-1">
            <v-text-field
                type="text"
                bg-color="#E1F5FE"
                label="区域码"
                variant="outlined"
                v-model="code_region"
                :disabled="isRun"
            ></v-text-field>

          </v-col>
          <v-col class="px-1">
            <v-text-field
                type="text"
                bg-color="#E1F5FE"
                label="经销商码"
                variant="outlined"
                v-model="code_distributor"
                :disabled="isRun"
            ></v-text-field>

          </v-col>
          <v-col class="px-1">
            <v-text-field
                type="text"
                bg-color="#E1F5FE"
                label="自定义码"
                variant="outlined"
                v-model="code_customer"
                :disabled="isRun"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- 设置秘钥按钮-->
        <v-row>
          <v-col class="pt-0 pb-6 d-flex justify-end ">
            <v-btn
                @click="setComm"
                color="primary"
                bottom
                class="mt-2 px-10 text-subtitle-1 "
                :disabled="isRun"
            >
              设置秘钥
            </v-btn>
          </v-col>
        </v-row>

        <!--串口-->
        <v-row class="mt-3">
          <p class="mr-7 pt-4"> 串口
          </p>
          <v-col class="py-0">
            <v-select
                loader-height="1"
                :items="select_items.Serial.items"
                v-model="select_items.Serial.select"
                solo
            ></v-select>
          </v-col>
        </v-row>
        <!--波特率-->
        <v-row class="mt-3">
          <p class="mr-2 pt-4"> 波特率
          </p>
          <v-col class="py-0">
            <v-select
                loader-height="1"
                :items="select_items.Baud.items"
                v-model="select_items.Baud.select"
                solo
            ></v-select>
          </v-col>
        </v-row>
        <!--数据位-->
        <v-row class="mt-3">
          <p class="mr-2 pt-4"> 数据位
          </p>
          <v-col class="py-0">
            <v-select
                loader-height="1"
                :items="select_items.Data.items"
                v-model="select_items.Data.select"
                solo
            ></v-select>
          </v-col>
        </v-row>
        <!--校验位-->
        <v-row class="mt-3">
          <p class="mr-2 pt-4"> 校验位
          </p>
          <v-col class="py-0">
            <v-select
                loader-height="1"
                :items="select_items.Check.items"
                v-model="select_items.Check.select"
                solo
            ></v-select>
          </v-col>
        </v-row>
        <!--停止位-->
        <v-row class="mt-3">
          <p class="mr-2 pt-4"> 停止位
          </p>
          <v-col class="py-0">
            <v-select
                loader-height="1"
                :items="select_items.Stop.items"
                v-model="select_items.Stop.select"
                solo
            ></v-select>
          </v-col>
        </v-row>

        <!--启动和暂停按钮-->
        <v-row>
          <!--暂停键-->
          <v-col class="d-flex justify-end">
            <v-btn
                @click="stopComm"
                color="primary"
                bottom
                class="mt-2 px-10 text-h6 "
                :disabled="!isRun"
            >
              暂停
            </v-btn>
          </v-col>
          <!--启动键-->
          <v-col class="d-flex justify-end">
            <v-btn
                @click="startComm"
                color="primary"
                bottom
                class="mt-2 px-10 text-h6 "
                :disabled="isRun"
            >
              启动
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex justify-end pt-8"><a
              href="https://cdn.chikytech.com.cn/tools/chipcheck/%E8%87%B4%E5%BC%80%E7%A7%91%E6%8A%80-%E5%A2%A8%E7%9B%92%E8%8A%AF%E7%89%87%E7%83%A7%E5%BD%95%E5%B7%A5%E5%85%B7.exe"
              class="text-decoration-underline"> 下载工具</a>
          </v-col>
          <v-col class="d-flex justify-end pt-8"><a
              href="https://cdn.chikytech.com.cn/tools/chipcheck/墨盒芯片烧录工具使用说明.pdf" target="view_window"
              class="text-decoration-underline"> 使用说明</a>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <!--      右边显示内容-->
      <v-col cols="8">
        <v-row>
          <!--      左边指示灯-->
          <v-col cols="2" class="mt text-center px-5 mt10 ">

            <v-card
                elevation="6"
                :color="colorRun"
                class="rounded-circle mx-auto mt-16"
                height="100" width="100"
            >
              <p class="mt-8 text-h5">{{ isRun ? '运行中' : '运行' }}</p>
            </v-card>

            <v-card
                elevation="6"
                :color="colorStop"
                class="rounded-circle mx-auto  my-16"
                height="100" width="100"
            >
              <p class="mt-8 text-h5">{{ isRun ? '' : '停止' }}</p>
            </v-card>

          </v-col>
          <!--    图形显示-->
          <v-col>
            <v-row class="">
              <v-col v-for="(item,index) in totalPicture.total" :key="index" cols="2" class="text-center ">
                {{ index + 1 }} 列
              </v-col>
            </v-row>
            <v-row class=" my-0" v-for="(item,index) in totalPicture.Color" :key="index">
              <v-col v-for="(item,index) in item" :key="index" cols="2" class="px-2">
                <v-card
                    :color="item"
                    class="mx-auto"
                    height="80">
                </v-card>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>


  </v-container>
</template>

<script>
// 计算CRC16校验码 使用方法：  let data = crc16('00 00 00 00 63 00 00 00 00 00 00 00');要求是十六进制字符表示，有无空格、大小写都无所谓，但是 0f 不可以写成 f, 00 不可写成 0
import crc16 from "@/components/crc16";

export default {
  name: 'HomeIndex',

  data() {

    return {
      // 分别对应四个字节的秘钥
      code_country: '',
      code_region: '',
      code_distributor: '',
      code_customer: '',
      // 下拉列表
      select_items: {
        Serial: {
          select: 'COM3',
          items: ['COM1', 'COM3'],
        },
        Baud: {
          select: '115200',
          items: ['9600', '19200', '38400', '57600', '115200'],
        },
        Data: {
          select: '8',
          items: ['5', '6', '7', '8'],
        },
        Check: {
          select: 'None',
          items: ['None', 'Even', 'Odd', 'Mask', 'Space'],
        },
        Stop: {
          select: 'One',
          items: ['None', 'One', 'OnePointFive', 'Two'],
        }
      },
      // 起始位/数据包长度 取4位
      num001: 0,
      // 序列号/校验和/停止位 都取2位
      num01: 2,
      // 数据包长度取2位
      num02: 12,
      // 数据包格式 初始化数据包是启动的命令 只需要在原来的基础上更改数据包内容即可，增加可复用性。 这是十六进制显示，换成十进制也可以，例如0x5a 可以换成 90
      /**
       * 详情请看《ZK168x系列喷码机通信协议规约》
       * 起始位      4    固定值，统一为0x5A0x4B0x430x54
       * 序列号      2    从开机后，每次发送数据序列号都自动加 1
       * 数据包类型   1    固定值，0x20
       * 数据包长度   4    代表传输的数据包长度，uint32_t型，小端模式
       * 命令代码    4    命令代码，uint32_t型，小端模式
       * 命令参数    4    命令参数，uint32_t型，小端模式
       * 保留字节    4    保留字节，无需解析
       * 检验和      2    数据包的CRC16校验值，接收方若收到的信息计算有CRC错误，则忽略该包
       * 停止位      2    固定值，统一为 0x0D 0x0A
       */
      // data_total: [0x5a, 0x4B, 0x43, 0x54, 0x01, 0x00, 0x20, 0x0C, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x64, 0x02, 0x0D, 0x0A],
      data_total: [0x5a, 0x4B, 0x43, 0x54, 0x01, 0x00, 0x20, 0x0C, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x64, 0x02, 0x0D, 0x0A],
      //数据包的序列号（范围：0~65536），起始位是1 对应小端模式两个字节的十六进制为 0x01 0x00
      data_number: 1,
      // 状态机的状态 表示走到哪一步，如果没有达到指定的条件就不会变化
      switchNumber: 0,
      // 接收的数据包 12个字节 十六进制
      serviceData: [],
      // 校验和
      checkNum: [],
      // 服务端地址
      webURI: 'ws://localhost:9587',
      // 网络通信实例
      webSocket: '',
      // 是否显示提示信息 默认为false
      snackbar: false,
      text_snackbar: `提示：服务器未打开...`,
      snackbar_color: '',
      snackbar_color_warning: 'orange-lighten-3',
      snackbar_color_success: 'light-green-darken-2',
      // 是否在运行状态 默认为false
      isRun: false,
      // 数据包中显示灯状态 （六个字节） （十进制）
      list: [],
      // 服务端是否已经连接？ 默认是false
      isConnection: false,
      // 停止状态灯颜色，默认为白色
      colorStop: '#ECEFF1',
      // 运行状态灯颜色，默认为白色
      colorRun: '#ECEFF1',
      GREEN: 'green',
      RED: 'red',
      WHITE: '#ECEFF1', //灰白色
      // 显示的数据 灯的状态
      totalPicture: {
        total: 6,
        Color: [
          ["#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1"],
          ["#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1"],
          ["#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1"],
          ["#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1"],
          ["#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1"],
          ["#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1", "#ECEFF1"],
        ],
      },
    }
  },
  created() {
    //页面刚进入时开启长连接
    this.initWebSocket();
  },
  mounted() {
    // let data_c = crc16("00 00 00 00 00 00 00 00 40 00 00 00")
    let data_c = crc16("000000003F3F3F3E3F7F0000")
    console.log("查看校验值==============", data_c)
    let datum = ["5a", "4b", "43", "54", "01", "18", "80", "0c", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "00", "40", "00", "00", "00", "71", "c2", "0d", "0a"];
    console.log("长度",datum.length)
    // for (let i = 0; i < datum.length; i++) {
    //   this.switchMachine(datum[i]);
    // }
  },
  unmounted() {
    //页面销毁时关闭长连接
    this.webSocketClose();
  },
  watch: {
    isConnection(newVal) {
      if (!newVal) {
        this.colorRun = this.WHITE;
        this.colorStop = this.WHITE;
      }
    }
  },
  methods: {


    // 当所有的输入合法后返回true
    check_code() {
      // 合法范围 [0,255]
      if (this.code_country > 255 || this.code_region > 255 || this.code_distributor > 255 || this.code_customer > 255) {
        return false;
      }
      if (this.code_customer < 0 || this.code_distributor < 0 || this.code_region < 0 || this.code_country < 0) {
        return false;
      }
      return true;
    },

    // 设置烧录秘钥
    setComm() {

      // 校验秘钥合法化
      if (!this.check_code()) {
        this.state_snackbar(this.snackbar_color_warning, '提示：请您输入正确的秘钥！')
        return;
      }
      // 录入序列号
      this.enterNumber();
      // 录入命令代码（指的是什么指令，暂停还是启动？）
      this.enterCommand(0x02);
      // 录入秘钥
      this.enterKey();
      // 录入校验码
      this.enterCheck();
      // 发送指令
      if (this.isConnection) {
        console.log("发送完整指令",this.data_total)
        this.sendData(this.data_total);
        this.state_snackbar(this.snackbar_color_success, '提示：设置秘钥成功！')
      } else {
        this.state_snackbar(this.snackbar_color_warning, '提示：设置秘钥失败，服务器未打开！')
      }


    },

    // 暂停烧录指令
    stopComm() {
      // let data = [0x5a, 0x4B, 0x43, 0x54, 0x58, 0x7D, 0x20, 0x0C, 0x00, 0x01, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x99, 0xc1, 0x0D, 0x0A];
      // 录入序列号
      this.enterNumber();
      // 录入命令代码（指的是什么指令，暂停还是启动？）
      this.enterCommand(0x01);
      // 录入校验码
      this.enterCheck();
      // 发送指令
      if (this.isConnection) {
        this.sendData(this.data_total);
        this.state_snackbar(this.snackbar_color_success, '提示：已停止烧录')
      } else {
        this.state_snackbar(this.snackbar_color_warning, '提示：操作失败，服务器未打开！')
      }
    },

    // 开始烧录指令
    startComm() {
      // let data = [0x5a, 0x4B, 0x43, 0x54, 0x58, 0x7D, 0x20, 0x0C, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x64, 0x02, 0x0D, 0x0A];
      // 录入序列号
      this.enterNumber();
      // 录入命令代码（指的是什么指令，暂停还是启动？）
      this.enterCommand(0x00);
      // 录入校验码
      this.enterCheck();
      // 发送指令
      if (this.isConnection) {
        this.sendData(this.data_total);
        this.state_snackbar(this.snackbar_color_success, '提示：启动成功！开始烧录芯片 ')
      } else {
        this.state_snackbar(this.snackbar_color_warning, '提示：设置启动失败，服务器未打开！')
      }
    },

    /**
     * 录入秘钥
     * @param key 秘钥
     */
    enterKey() {
      if (!this.isConnection) {
        return;
      }
      // 初始化秘钥是 0 0 0 0 （国家码 区域码 经销商码 自定义码）
      if (this.code_customer === '' || this.code_customer.length === 0) {
        this.code_customer = 0;
      }
      if (this.code_distributor === '' || this.code_distributor.length === 0) {
        this.code_distributor = 0;
      }
      if (this.code_region === '' || this.code_region.length === 0) {
        this.code_region = 0;
      }
      if (this.code_country === '' || this.code_country.length === 0) {
        this.code_country = 0;
      }
      this.data_total[15] = parseInt(this.code_customer);
      this.data_total[16] = parseInt(this.code_distributor);
      this.data_total[17] = parseInt(this.code_region);
      this.data_total[18] = parseInt(this.code_country);
    },

    /**
     *  录入命令代码
     * @param key 开始烧录 0x00
     *            暂停烧录 0x01
     *            设置密钥 0x02
     */
    enterCommand(key) {
      this.data_total[11] = key;
    },
    /**
     * 录入序列号
     */
    enterNumber() {
      let num01 = this.data_number & 0xff;  // 对255求除数
      let num02 = this.data_number >> 8; // 对255求余

      this.data_total[4] = num01;
      this.data_total[5] = num02;
      this.data_number++; // 自动加一
    },

    /**
     * 发送数据包到服务端
     * @param data
     */
    sendData(data) {
      let bytes = new Uint8Array(data);
      this.websocketSend(bytes);
    },

    /**
     * 显示弹窗信息
     * @param color 颜色
     * @param text  信息
     */
    state_snackbar(color, text) {
      if (color.length > 0) {
        this.snackbar_color = color;
      }
      if (text.length > 0) {
        this.text_snackbar = text;
      }
      this.snackbar = true;
    },

    /**
     * 录入校验码 ，采用CRC16校验，只校验数据包部分
     */
    enterCheck() {

      let check = ''; // 十六进制的内容（数据包的十二个字节 例如000000000100000000000000）
      // 拿到数据包内容
      for (let i = 11; i < 23; i++) {
        check += this.toHex(this.data_total[i]);
      }
      // 校验结果
      let crc16_check = crc16(check);
      console.log("校验字段",check)
      console.log("crc16_check检查", crc16_check)
      let res_pre = '0x' + crc16_check.toString().substring(0, 2);
      let res_after = '0x' + crc16_check.toString().substring(2, 4);
      this.data_total[23] = eval(res_pre);
      this.data_total[24] = eval(res_after);
    },

    initWebSocket() { //初始化websocket

      // const wsUri = 'ws://localhost:9000';//ws地址
      this.webSocket = new WebSocket(this.webURI);
      // 动态绑定
      this.webSocket.onopen = this.websocketOnOpen;
      this.webSocket.onerror = this.websocketOnError;
      this.webSocket.onmessage = this.websocketOnMessage;
      this.webSocket.onclose = this.webSocketClose;
    },

    websocketOnOpen() {
      this.isConnection = true;
      this.colorStop = this.GREEN;
      console.log("WebSocket连接成功");
      this.state_snackbar(this.snackbar_color_success, '提示：服务器连接成功！');
    },
    websocketOnError(e) { //错误
      console.log("WebSocket连接发生错误", e);

    },
    //数据接收
    websocketOnMessage(e) {
      let uint8Array = e.data.toString();
      let data = uint8Array.split(",");
      // console.log("循环接收**********》", uint8Array);
      for (let datum of data) {
        this.switchMachine(datum);
      }
    },
    //数据发送
    websocketSend(agentData) {
      // 如果已连接服务端则发送数据，否则显示提示信息
      this.webSocket.send(agentData);
    },

    webSocketClose() { //关闭
      console.log("服务端已断开 connection closed ");
      this.isConnection = false;
      this.state_snackbar(this.snackbar_color_warning, '提示：服务器已断开！');
    },

    //重置状态机
    ResetMachine() {
      // 起始位取4位
      this.num001 = 0;
      this.num01 = 2;
      this.num02 = 8;
      this.switchNumber = 0;
    },
    // 状态机判断器 用于接收协议，以字节为单位接收，如果不符合则重头开始判断。
    switchMachine(val) {
      let value = this.toHex(val);
      // console.log("16进制:", value);
      switch (this.switchNumber) {
        case 0:
          switch (this.num001) {
            case 0:
              if (value === '5A') {
                this.num001 = 1;
              }
              break;
            case 1:
              if (value === '4B') {
                this.num001 = 2;
              } else if (value === '5A') {   // 如果有出现5A开头的就重头开始
                this.num001 = 1;
              } else {
                this.num001 = 0;
              }
              break;
            case 2:
              if (value === '43') {
                this.num001 = 3;
              } else if (value === '5A') {
                this.num001 = 1;
              } else {
                this.num001 = 0;
              }
              break;
            case 3:
              if (value === '54') {
                this.num001 = 0;
                this.switchNumber = 4;  // 进入下一个判断
              } else if (value === '5A') {
                this.num001 = 1;
              } else {
                this.num001 = 0;
              }
              break;
              // 下面可以不写，只是为了防止出现意外
            default:
              if (value === '5A') {
                this.num001 = 1;
              } else {
                this.num001 = 0;
              }
              break;
          }
          break;
          // 序列号 2个字节
        case 4:
          if (this.num01 > 1) {
            this.num01--;
          } else {
            this.switchNumber = 5;
            this.num01 = 2;
          }
          break;
          // 数据包类型 1个字节
        case 5:
          if (value === '80') {
            this.switchNumber = 6;
          } else {
            this.ResetMachine();
          }
          break;
          // 数据包长度 4个字节
        case 6:
          if (this.num001 < 3) {
            this.num001++;
          } else {
            this.switchNumber = 7;
            // 重置
            this.num001 = 0;
          }
          break;
          // 数据包 12个字节
        case 7:
          this.serviceData.push(value);
          // 数据包总共是12个字节，将[4,9]的字节存到this.list去
          if (this.num02 < 9 && this.num02 > 2) {
            this.list.push(val);
          }
          if (this.num02 > 1) {
            this.num02--;
          } else {
            this.switchNumber = 8;
            this.num02 = 12;
          }
          break;
          // 校验码 两个字节
        case 8:
          this.checkNum.push(value);
          if (this.num01 > 1) {
            this.num01--;
          } else {
            //  1、接收校验码完成，校验数据包是否正确
            // 数据包 转换成 可校验的格式化 内容 000000003F3F3F3E3F7F0000
            let _this_data = this.serviceData.toString().replace(/,/g, "");
            let data_check = crc16(_this_data);            // 数据包校验结果  F953
            let _this_data_checkNum = this.checkNum.toString().replace(/,/g, "");// 数据本身的校验码装换成字符串 F953

            //  2、判断校验是否成功
            if (_this_data_checkNum === data_check) {
              // 显示灯的状态
              this.datalist();
              // 如果是运行状态 显示秘钥
              if (this.isRun) {
                // console.log("最最终的数据", this.serviceData);
                this.code_customer = parseInt(this.serviceData[0], 16);
                this.code_distributor = parseInt(this.serviceData[1], 16);
                this.code_region = parseInt(this.serviceData[2], 16);
                this.code_country = parseInt(this.serviceData[3], 16);
              }
            }

            this.switchNumber = 9;
            this.num01 = 2;
            // 3、重置接收对象
            this.serviceData = [];
            this.checkNum = [];
            this.list = [];
          }

          break;
          // 停止位 两个字节 不用处理
        case 9:
          if (value === '0D') {
            this.switchNumber = 10;
          } else {
            this.switchNumber = 0;
          }
          break;
        case 10:
          this.switchNumber = 0;
          break;
      }
    },
    // 十进制转换为十六进制 例如 90 ==> 5A; 0 ==> 00; 10 ==> 0A
    // 0xff 也可以转换为 FF ，请放心使用
    toHex(num) {
      if (num === '0' || num === 0) {
        return '00';
      }
      let a = [];
      while (num != 0) {
        let c = num % 16;
        switch (c) {
          case 0 :
            a.push('0');
            break;
          case 1 :
            a.push("1");
            break;
          case 2 :
            a.push("2");
            break;
          case 3 :
            a.push("3");
            break;
          case 4 :
            a.push("4");
            break;
          case 5 :
            a.push("5");
            break;
          case 6 :
            a.push("6");
            break;
          case 7 :
            a.push("7");
            break;
          case 8 :
            a.push("8");
            break;
          case 9 :
            a.push("9");
            break;
          case 10 :
            a.push("A");
            break;
          case 11 :
            a.push("B");
            break;
          case 12 :
            a.push("C");
            break;
          case 13 :
            a.push("D");
            break;
          case 14 :
            a.push("E");
            break;
          case 15 :
            a.push("F");
            break;
        }

        num = parseInt(num / 16);
      }
      // 如果长度少于2，自动补0，保证 F的输出是0F
      if (a.length < 2) {
        a.push(0);
      }
      return a.reverse().join('')
    }
    ,
// ********************************************分隔符，下面是旧代码********************************************************************


// 全变绿色
    ChangeGreen() {
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 6; j++) {
          this.totalPicture.Color[i][j] = '#F06292';
        }
      }
    }
    ,
// 全变白色
    ChangeWhite() {
      for (let i = 0; i < 6; i++) {
        for (let j = 0; j < 6; j++) {
          this.totalPicture.Color[i][j] = this.WHITE;
        }
      }
    }
    ,
// 处理数据 改变灯的状态
    datalist() {
      for (let i = 5; i >= 0; i--) {
        let num = this.list[5 - i];
        for (let j = 0; j < 6; j++) {
          if (num % 2 === 1) {
            this.totalPicture.Color[j][i] = this.GREEN;// 录入成功
          } else {
            this.totalPicture.Color[j][i] = this.WHITE;// 录入失败
          }
          num = Math.floor(num / 2);
        }
      }
      // 判断状态灯的情况，运行中 或者 停止
      let nmbRun = this.list[4];

      if (nmbRun > 63) {
        // 运行状态
        this.isRun = true;
        this.colorRun = this.GREEN;
        this.colorStop = this.WHITE;
      } else {
        // 停止状态
        this.isRun = false;
        this.colorStop = this.GREEN;
        this.colorRun = this.WHITE;
      }
    },

  }
}
</script>
