<template>
  <v-app>
    <v-main class="bg-blue-lighten-3">
      <HelloWorld/>
    </v-main>
    <v-footer class=" bg-blue-lighten-3 text-center d-flex justify-center"><div>
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
        粤ICP备16004202号
      </a>
      </div></v-footer>
  </v-app>
</template>

<script>
import HelloWorld from './components'

export default {
  name: 'App',

  components: {
    HelloWorld,
  },

  data: () => ({
    //

  }),
}
</script>
